<template>
  <div class="bg-main login-page2">
    <div style="height: 100vh">
      <img class="imgLogin" src="./img/login.jpg" />
    </div>
    <div class="app-container">
      <div class="container-loginBox">
        <div class="login-box">
          <!-- /.login-logo -->
          <div class="card-outline">
            <div class="card-header text-center title">
              <b>Pour<img src="./img/A.svg" width="40px" />ccord</b>.com
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

      <p class="catchphrase">La gestion locative automatisée.</p>
      <p class="baseline">
        Pilotez votre activité <br />et<br />
        améliorez votre relation avec vos locataires !
      </p>
      <!--div class="newsletter">
        <label for="email" style="margin-bottom: 10px">Tenez moi au courant ! </label>
        <input type="email" id="email" v-model="email" placeholder="adresse email" />
        <button @click="saveEmail" class="btnOK">S'inscrire</button>
      </div-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance } from "vue";
import axios from "axios";

export default defineComponent({
  name: "App",
  setup() {
    const email = ref<string>("");

    const saveEmail = () => {
      if (email.value) {
        // Call the function to save the email
        axios
          .post("/save-email", { email: email.value })
          .then(() => {
            alert("Email saved successfully!");
          })
          .catch((error) => {
            console.error("Error saving email:", error);
            alert("There was an error saving the email.");
          });
      } else {
        alert("Please enter a valid email address.");
      }
    };

    return {
      email,
      saveEmail,
    };
  },
  mounted() {
    // Accessing gtag using getCurrentInstance
    const instance = getCurrentInstance();
    const gtag = instance?.appContext.config.globalProperties.$gtag;

    if (gtag) {
      gtag.pageview({
        page_path: "/", // Adjust according to the page being loaded
      });
    }
  },
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
template {
  margin: 0 !important;
}

img {
  vertical-align: middle;
}
input[type="email"]::placeholder {
  text-align: center; /* Center placeholder text horizontally */
  color: #888; /* Optional */
  font-family: "Source Sans Pro";
}

.bg-main-inverted {
  background: #007bff linear-gradient(180deg, #268fff, #222f3e) repeat-x !important;
  color: #fff;
}

.bg-main-inverted_ok {
  background: #54ff00 linear-gradient(180deg, #31ff26, #222f3e) repeat-x !important;
  color: #fff;
}
pf-button {
  --pf-display: block;
  --pf-width: 100%;
}

.imgLogin {
  height: 100vh;
}

.login-page2 {
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  height: 100vh;
  -ms-flex-pack: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: center;
  flex-direction: row;
}

.h100 {
  height: 100%;
}

.container-loginBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: white !important;
}

.btnOK {
  background: #4343468a;

  color: white;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-width: var(--pf-width, 2.5rem);
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  display: var(--pf-display, inline-block);
  width: var(--pf-width, auto);
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  width: 100%;
  border-color: #837c7c;
}

.card-header {
  border-bottom: 1px solid rgb(247 247 247 / 23%) !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.catchphrase {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.baseline {
  font-size: 1.5rem;
  margin-bottom: 40px;
  text-align: center;
}

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="email"] {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}
</style>
