import { createApp } from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";

import "./assets/global.css";
const app = createApp(App);

app.use(VueGtag, {
  config: { id: "G-30FRVFXQ9S" }, // Replace with your Google Analytics ID
});

app.mount("#app");
