import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './img/login.jpg'
import _imports_1 from './img/A.svg'


const _hoisted_1 = { class: "bg-main login-page2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div style=\"height:100vh;\" data-v-4e1b26ce><img class=\"imgLogin\" src=\"" + _imports_0 + "\" data-v-4e1b26ce></div><div class=\"app-container\" data-v-4e1b26ce><div class=\"container-loginBox\" data-v-4e1b26ce><div class=\"login-box\" data-v-4e1b26ce><div class=\"card-outline\" data-v-4e1b26ce><div class=\"card-header text-center title\" data-v-4e1b26ce><b data-v-4e1b26ce>Pour<img src=\"" + _imports_1 + "\" width=\"40px\" data-v-4e1b26ce>ccord</b>.com </div></div></div></div><p class=\"catchphrase\" data-v-4e1b26ce>La gestion locative automatisée.</p><p class=\"baseline\" data-v-4e1b26ce> Pilotez votre activité <br data-v-4e1b26ce>et<br data-v-4e1b26ce> améliorez votre relation avec vos locataires ! </p></div>", 2)
  ])))
}